<template>
	<!-- eslint-disable max-lines-->
	<SiteScannerLayout
		v-if="report"
		:header-title="'Cookie Scanner' | useLabels"
		header-caption="Your scan results"
	>
		<template #content>
			<div class="cassie-vertical-md">
				<SectionCard>
					<template #title>
						Scan Results for {{ report.domain }} - scanned on {{ format(new Date(report.scanDate), DATE_FORMAT) }}
					</template>
					<template #body>
						<div class="cassie-vertical-md">
							<p class="text-subtitle-2">
								What cookies are on your site?
							</p>
							<p>
								First and third party cookies
							</p>
							<p>
								First party cookies are created by and belong to your organisation. Third party cookies are created by another organisation (usually a tool or service). Essentially, you have no control over the content of third party cookies.
							</p>
							<v-divider />
							<v-row>
								<v-col cols="6">
									<span class="text-subtitle-2">
										Your Risk
									</span>

									<div>
										<v-img
											width="100px"
											:src="riskImage"
										/>
									</div>
								</v-col>
								<v-col cols="6">
									<span class="text-subtitle-2">
										How we calculate this
									</span>
									<p>
										{{ report.riskCalculationText }}
									</p>
									<p class="text-caption">
										Please note this risk rating is an approximation to help you with the formation of your cookie management strategy and should not be relied upon as proof of compliance level.
									</p>
								</v-col>
							</v-row>
							<v-row v-if="report.isAggregateReport">
								<v-spacer />
								<v-col
									cols="3"
									class="text-center"
								>
									<a @click="scrollToSection('cookies')">
										Click to see Cookies
									</a>
								</v-col>
								<v-col
									cols="3"
									class="text-center"
								>
									<a @click="scrollToSection('advancedTrackers')">
										Click to see Advanced Trackers
									</a>
								</v-col>
								<v-col
									cols="3"
									class="text-center"
								>
									<a @click="scrollToSection('subResources')">
										Click to see Sub Resources
									</a>
								</v-col>
								<v-spacer />
							</v-row>
						</div>
					</template>
				</SectionCard>
				<SectionCard ref="cookies">
					<template #title>
						Cookies
					</template>
					<template #title-action>
						<PrimaryActionButton
							v-if="report.isAggregateReport && userCanUseSiteScanner"
							@click="downloadCsv(downloadTypeEnum.COOKIE_REPORT)"
						>
							Download Cookies
						</PrimaryActionButton>
					</template>
					<template #body>
						<div class="cassie-vertical-md">
							<div>
								<p>
									Cookies found on {{ report.domain }}
								</p>
								<p>
									The following is a list of all the cookies that our scanner identified on your site. For each cookie, we've identified whether it is first or third party, what it is called, it's domain, and expiry.
								</p>
								<v-row>
									<v-spacer />
									<v-col
										class="text-center"
										cols="8"
									>
										<v-row>
											<v-col>
												<span class="text-subtitle-2">
													Total Number of Cookies
												</span>
												<div class="text-h4">
													{{ report.totalNumberOfCookies }}
												</div>
											</v-col>
											<v-col>
												<span class="text-subtitle-2">
													Number of First Party Cookies
												</span>
												<div class="text-h4">
													{{ report.firstPartyCookies }}
												</div>
											</v-col>
											<v-col>
												<span class="text-subtitle-2">
													Number of Third Party Cookie
												</span>
												<div class="text-h4">
													{{ report.thirdPartyCookies }}
												</div>
											</v-col>
										</v-row>
									</v-col>
									<v-spacer />
								</v-row>
							</div>
							<DataTable
								:headers="cookieTableHeaders"
								:items="report.cookies"
								@click:row="openCookieDetailsModal"
							>
								<template #item.cookieName="{ item }">
									<a
										v-if="report.isAggregateReport && userCanUseSiteScanner && !siteScannerV2"
										@click="downloadSingularCookie(item.cookieName)"
									>
										{{ item.cookieName }}
									</a>
									<span
										v-else
									>
										{{ item.cookieName }}
									</span>
								</template>
								<template
									v-if="report.isAggregateReport"
									#item.description="{ item }"
								>
									{{ truncatedDescription(item.description) }}
								</template>
								<template
									v-if="report.isAggregateReport"
									#item.cookieCount="{ item }"
								>
									<v-progress-linear
										:value="getPercentage(item.cookieCount)"
										height="20"
									>
										<strong>
											{{ cookieUrlCount(item.cookieCount) }} / {{ totalUrls }}
										</strong>
									</v-progress-linear>
								</template>
								<template
									v-if="userCanUseSiteScanner"
									#item.action="{ item }"
								>
									<IconButton
										v-if="userCanUseSiteScanner"
										tooltip-text="Edit cookie details"
										@click.stop="openCookieDetailsModal(item)"
									>
										mdi-pencil
									</IconButton>
								</template>
							</DataTable>
						</div>
					</template>
				</SectionCard>
				<SectionCard
					v-if="report.advancedTrackers && report.advancedTrackers.length >= 1"
					ref="advancedTrackers"
				>
					<template #title>
						Advanced Trackers
					</template>
					<template #title-action>
						<PrimaryActionButton
							v-if="userCanUseSiteScanner"
							@click="downloadCsv(downloadTypeEnum.ADVANCED_TRACKERS)"
						>
							Download Advanced Trackers
						</PrimaryActionButton>
					</template>
					<template #body>
						<div class="cassie-vertical-md">
							<p>
								Advanced user tracking and fingerprinting techniques are used by websites to bypass privacy protection in web browsers and increase tracking persistence
							</p>
							<p>
								Our scanner found {{ report.advancedTrackers.length }} items relating to this:
							</p>
							<v-expansion-panels
								:value="expandedByDefault"
								multiple
							>
								<v-expansion-panel
									v-for="(tracker, index) in report.advancedTrackers"
									:key="index"
								>
									<v-expansion-panel-header>
										{{ tracker.advancedTrackerName }}
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<div class="cassie-vertical-md">
											<p>
												Domain: {{ tracker.advancedTrackerDomain }}
											</p>
											<p>
												Tracker URL: {{ tracker.advancedTrackerUrl }}
											</p>
											<p>
												Found on Domain: {{ tracker.foundOnDomain }}
											</p>
											<p>
												{{ tracker.advancedTrackerType }}
											</p>
										</div>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</div>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						HTTP Security Related Headers Assessment
					</template>
					<template #body>
						<div class="cassie-vertical-md">
							Location
							<span class="text-subtitle-2">
								{{ report.domain }}
							</span>
							<p>
								The HTTP Location is being returned by a server to redirect the web browser to a new URL of the requested resource. The URL may be relative (/index.html) or absolute (https://example.com).
							</p>
							<p v-if="report.securityRankScore">
								Security Score: {{ report.securityRankScore }}
							</p>
							<p v-if="report.rankLetter">
								TLS Score: {{ report.rankLetter }}
							</p>
							<DataTable
								v-if="report.headerAlerts && report.headerAlerts.length > 0"
								:headers="headerAlertsTableHeaders"
								:items="report.headerAlerts"
							/>
						</div>
					</template>
				</SectionCard>
				<SectionCard
					v-if="report.showSubResources"
					ref="subResources"
				>
					<template #title>
						Sub Resources
					</template>
					<template #title-action>
						<PrimaryActionButton
							v-if="userCanUseSiteScanner"
							@click="downloadCsv(downloadTypeEnum.SUB_RESOURCES)"
						>
							Download Sub Resources
						</PrimaryActionButton>
					</template>
					<template #body>
						<div class="cassie-vertical-md">
							<p>
								Most web pages load several sub resources such as images, style sheets (CSS), Javascript files, web fonts, audio or video files and other web pages in frames. Each of these sub resources may be loaded from the same server (first-party resource) or servers belonging to other parties (third-party resources). In the latter case, the third party will see a request coming from your browser with the information on the originating page and it can set its own cookies, both of which are frequently used for user tracking.
							</p>
							<p>
								<span class="text-subtitle-2">
									Note
								</span>
								that the cookies set by these sub-resources are already recorder in our cookie statistics for this page.
							</p>
						</div>
						<Modal
							v-if="showCookieDetailsModal"
							width="800px"
						>
							<template #modal-title>
								Cookie - {{ selectedCookie.cookieName }}
								<v-spacer />
								<IconButton @click="closeSubmissionModal()">
									mdi-close
								</IconButton>
							</template>v
							<template #modal-content>
								<div class="cassie-vertical-md">
									<TextArea
										v-model="selectedCookie.description"
										:disabled="!userCanUseSiteScanner"
										rows="5"
										label="Cookie Description"
										:rules="{max: 1000}"
										tooltip-text="The Cookie Description. This can be updated to wording of your choice if required."
									/>
									<v-row dense>
										<v-col :cols="6">
											<PrimaryActionButton
												:disabled="!userCanUseSiteScanner"
												tooltip-text="Click to restore the default description provided by Cassie where available. This will apply to all scans where this Cookie was captured. The 'Save Changes' button will commit any updates made."
												@click="restoreDefaultDescription()"
											>
												Restore Default Description
											</PrimaryActionButton>
										</v-col>
										<v-col :cols="6">
											<Dropdown
												v-model="selectedCookie.categoryId"
												:disabled="!userCanUseSiteScanner"
												:items="report.cookieCategories"
												label="Category"
												item-value="categoryId"
												item-text="categoryName"
												tooltip-text="A known Cookie is assigned a Category. This Category can be updated if required."
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											class="d-flex align-center"
											:cols="2"
										>
											<span>
												Found in URLs
											</span>
										</v-col>
										<v-col
											class="d-flex align-center"
											:cols="3"
										>
											<v-progress-linear
												:value="getPercentage(selectedCookie.cookieCount)"
												height="20"
											>
												<strong>
													{{ cookieUrlCount(selectedCookie.cookieCount) }} / {{ totalUrls }}
												</strong>
											</v-progress-linear>
										</v-col>
										<v-col
											class="d-flex align-center"
											:cols="1"
										>
											<v-tooltip
												slot="append"
												top
											>
												<template #activator="{ on }">
													<v-icon
														light
														class="ml-4"
														v-on="on"
													>
														mdi-information
													</v-icon>
												</template>
												<span>
													{{ '"Found in URLs" advises how many pages this Cookie was discovered on during this scan.' }}
												</span>
											</v-tooltip>
										</v-col>
										<v-col
											:cols="4"
											class="pl-1"
										>
											<span class="text-subtitle-1">
												Type: {{ selectedCookie.type }}
											</span>
										</v-col>
									</v-row>
									<v-row>
										<v-col :cols="4">
											<PrimaryActionButton
												:disabled="!userCanUseSiteScanner"
												@click="downloadSingularCookie(selectedCookie.cookieName)"
											>
												Download Cookie
											</PrimaryActionButton>
										</v-col>
									</v-row>
								</div>
							</template>
							<template #modal-footer>
								<SecondaryActionButton @click="closeSubmissionModal">
									Close
								</SecondaryActionButton>
								<PrimaryActionButton
									v-if="userCanUseSiteScanner"
									@click="updateSelectedCookieDetails()"
								>
									{{ 'Save Changes' }}
								</PrimaryActionButton>
							</template>
						</Modal>
					</template>
				</SectionCard>
			</div>
		</template>
		<template #footer>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="backToOverview">
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						@click="downloadReport"
					>
						Download Report
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</template>
	</SiteScannerLayout>
</template>
<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import SiteScannerLayout from './site-scanner-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import { SITE_SCANNER_READ_ONLY } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { siteScannerVersion } from '../../../../../../shared/state/config-keys.js'
import { getScanReport, downloadScanFile, updateCookieDetails } from '../../../../../../shared/utils/api/site-scanner.js'
import { downloadTypeEnum } from './download-type-enums.js'
import downloadCSVMixin from '../../../../../../shared/mixins/download-csv.js'
import generatePDFMixin from '../../../../../../shared/mixins/generate-pdf.js'
import Modal from '../../../../../../shared/components/modal.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import TextArea from '../../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'

export default {
	components: { SiteScannerLayout, PageActionRow, SectionCard, PrimaryActionButton, SecondaryActionButton, DataTable, Modal, IconButton, TextArea, Dropdown },
	mixins: [downloadCSVMixin, generatePDFMixin],
	props: {
		scanGuid: String,
		maxUrls: Number
	},
	setup () {
		return {
			format,
			DATE_FORMAT,
			downloadTypeEnum,
			siteScannerVersion
		}
	},
	data () {
		return {
			report: null,
			expandedByDefault: [],
			showCookieDetailsModal: false,
			selectedCookie: null,
			currentCookie: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		riskImage () {
			if (this.report.riskText === 'High') return require('../../../../assets/images/HighFilled.png')
			else if (this.report.riskText === 'Medium') return require('../../../../assets/images/MediumFilled.png')
			else return require('../../../../assets/images/LowFilled.png')
		},
		totalUrls () {
			if (this.report.totalUrls > this.maxUrls) {
				return this.maxUrls
			} else {
				return this.report.totalUrls
			}
		},
		headerAlertsTableHeaders () {
			return [
				{ text: 'Alert Text', value: 'alertText' },
				{ text: 'Header Name', value: 'headerName' }
			]
		},
		userCanUseSiteScanner () {
			return this.productAreaPermission(SITE_SCANNER_READ_ONLY)
		},
		siteScannerV2 () {
			const siteScannerVersion2 = 2
			return siteScannerVersion.value === siteScannerVersion2
		},
		truncatedDescription () {
			return description => {
				const maxLength = 40
				if (description.length <= maxLength) {
					return description
				} else {
					return description.substring(0, maxLength) + ' ...'
				}
			}
		},
		cookieTableHeaders () {
			if (siteScannerVersion.value === 2 && this.report.isAggregateReport) {
				return [
					{ text: 'Cookie Name', value: 'cookieName' },
					{ text: 'Category', value: 'category' },
					{ text: 'Description', value: 'description' },
					{ text: 'Classification', value: 'classification' },
					{ text: 'Domain', value: 'domain' },
					{ text: 'Expiry (in days)', value: 'expiryInDays' },
					{ text: 'Action', value: 'action', width: '7%' }

				]
			} else {
				return [
					{ text: 'Type', value: 'type' },
					{ text: 'Cookie Name', value: 'cookieName' },
					{ text: 'Classification', value: 'classification' },
					{ text: 'Domain', value: 'domain' },
					{ text: 'Expiry (in days)', value: 'expiryInDays' }
				]
			}
		}
	},
	created () {
		this.getScanReport()
	},
	methods: {
		backToOverview () {
			this.$router.push({ name: 'cookie-scanner' })
		},
		cookieUrlCount (cookieCount) {
			if (cookieCount > 1000) {
				return 1000
			} else {
				return cookieCount
			}
		},
		getPercentage (value) {
			return Math.round((value / this.totalUrls) * 100)
		},
		async getScanReport () {
			const { data } = await getScanReport(this.scanGuid)
			this.report = data
			this.completeTableData()
			this.advancedTrackersExpanded()
		},
		completeTableData () {
			if (this.report.isAggregateReport && siteScannerVersion.value !== 2) {
				this.cookieTableHeaders.push({ text: 'Found in URLs', value: 'cookieCount' })
			} else if (!this.report.isAggregateReport) {
				this.cookieTableHeaders.push({ text: 'Category', value: 'nonAggregateReportCategory' })
			}
		},
		openCookieDetailsModal (row) {
			if (siteScannerVersion.value === 2) {
				this.loading = true
				this.selectedCookie = JSON.parse(JSON.stringify(row))
				this.currentCookie = row
				this.showCookieDetailsModal = true
			}
		},
		closeSubmissionModal () {
			this.selectedCookie = null
			this.currentCookie = null
			this.showCookieDetailsModal = false
		},
		restoreDefaultDescription () {
			this.selectedCookie.description = this.selectedCookie.defaultCookieDescription
		},
		async updateSelectedCookieDetails () {
			const updateCookieDetailsRequest = {
				CookieName: this.selectedCookie.cookieName,
				CookieCategory: this.selectedCookie.categoryId,
				AlternativeDescription: this.selectedCookie.description
			}
			await updateCookieDetails(updateCookieDetailsRequest)
			this.currentCookie.category = this.report.cookieCategories.find(({ categoryId }) => categoryId === this.selectedCookie.categoryId).categoryName
			this.currentCookie.description = this.selectedCookie.description
			this.currentCookie.categoryId = this.selectedCookie.categoryId
			this.selectedCookie = null
			this.currentCookie = null
			this.showCookieDetailsModal = false
		},
		advancedTrackersExpanded () {
			if (this.report.advancedTrackers?.length === 0) return
			this.expandedByDefault = Array.from(Array(this.report?.advancedTrackers?.length).keys())
		},
		scrollToSection (section) {
			this.$refs[section].$el.scrollIntoView({ behavior: 'smooth' })
		},
		async downloadReport () {
			const response = await downloadScanFile(this.scanGuid, downloadTypeEnum.PDF, null)
			this.generatePdf(response.data, 'Cassie Cookie Scan Result.pdf')
		},
		async downloadCsv (downloadType) {
			const response = await downloadScanFile(this.scanGuid, downloadType, null)
			const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
			this.generateCsv(response.data, fileName)
		},
		async downloadSingularCookie (cookieName) {
			const response = await downloadScanFile(this.scanGuid, downloadTypeEnum.COOKIE_REPORT, cookieName)
			const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
			this.generateCsv(response.data, `${cookieName} - ${fileName}`)
		},
		async downloadSubResources () {
			const response = await downloadScanFile(this.scanGuid, downloadTypeEnum.SUB_RESOURCES, null)
			const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
			this.generateCsv(response.data, fileName)
		}
	}
}
</script>
